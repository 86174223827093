'use client';

import { FC } from 'react';
import { AuthContent } from '@/components/auth/auth-content';
import { SignInForm } from '@/components/auth/sign-in-form';
import { Footer } from '@/components/layout/footer';
import useAuthRedirect from '@/hooks/use-auth-redirect';
const LoginPage: FC = () => {
  useAuthRedirect();
  return <div className="flex flex-col h-screen justify-center items-center gap-8 w-full max-w-sm px-6" data-sentry-component="LoginPage" data-sentry-source-file="page.tsx">
      <AuthContent title="welcome back" data-sentry-element="AuthContent" data-sentry-source-file="page.tsx" />
      <SignInForm showRedirectLink data-sentry-element="SignInForm" data-sentry-source-file="page.tsx" />
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
    </div>;
};
export default LoginPage;